import React from "react";
import Post, { Screenshot } from "../components/Post";

import minimotorsLa from "./assets/002/minimotors-la.jpg";
import minimotorsTitle from "./assets/002/minimotors-title.png";
import minimotorsChoices from "./assets/002/minimotors-choices.png";
import minimotorsProgress1 from "./assets/002/minimotors-progress1.png";
import minimotorsProgress2 from "./assets/002/minimotors-progress2.png";

import psychonautsTitle from "./assets/002/psychonauts-title.png";
import psychonautsCG from "./assets/002/psychonauts-cg.png";
import psychonautsInGame from "./assets/002/psychonauts-ingame.png";

export default function WeekendGameJournal() {
  return (
    <Post title={"weekend game journal"} postDate={"july 26, 2021"}>
      <img src={minimotorsTitle} title="mini motors game title screen" />
      <p>
        This past weekend I spent time with something old, and something new.
        Those two games were <em>Psychonauts</em>, and <em>Mini Motorways</em>.
      </p>
      <p>
        <em>Mini Motorways</em> is a very similar concept to a previous game
        called <em>Mini Metro</em>. This time, instead of building metro lines,
        you’re building roads. I actually found this game to be quite a bit more
        relaxing than <em>Mini Metro</em>, though I also felt like there was a
        bit less strategy involved.
      </p>
      <p>
        Each level features slightly different terrain, but more or less the
        exact same gameplay. You start with a single house, and a single
        destination, and must connect them with a road. Once connected, cars
        begin to travel to their destinations. As time goes on, more houses, and
        more destinations pop up and you must continue to build roads for cars
        to travel on. As congestion builds, you need to utilize elements such as
        roundabouts, traffic lights, tunnels, bridges, and highways to relieve
        it. Eventually, when enough cars fail to reach their destination in
        time, it’s game over.
      </p>
      <p>
        If you like casual puzzle games, I definitely recommend checking out{" "}
        <em>Mini Motorways</em>, and maybe even <em>Mini Metro</em> if you
        missed it.
      </p>
      <img src={psychonautsTitle} title="psychonauts title screen" />
      <p>
        <em>Psychonauts</em> on the other hand is one of my all time favorite
        games, but one I haven’t played in many years. In fact, last time I
        played through it was on an original Xbox, on a tube-driven television,
        many thousand miles away from where I currently live. Playing it again I
        have been really surprised at how well it aged. It really is a great
        game. The biggest downfall are some pre-rendered cutscenes that are
        really low quality now, but the in game graphics actually look great.
        It’s a bummer the cutscenes don’t use the game engine or else they would
        have held up much better.
      </p>
      <p>
        Aside from that I did some browsing of the current early access
        offerings, and other things the mighty Steam-engine surfaced to me and
        found some interesting looking titles I’ll mention later in the week. I
        also plan on making some videos of <em>Mini Motorways</em> to post, and
        eventually <em>Psychonauts</em> but I probably won’t get to that for
        quite awhile.
      </p>
      <p>Here are some screenshots to enjoy, until next time.</p>
      <Screenshot
        image={minimotorsLa}
        title="screenshot of mini motors game LA stage"
        caption="Mini Motors LA Stage Final"
      />
      <Screenshot
        image={minimotorsProgress1}
        title="screenshot of mini motors game"
        caption="Everything starts off simple..."
      />
      <Screenshot
        image={minimotorsProgress2}
        title="screenshot of mini motors game"
        caption="Then gets more complex"
      />
      <Screenshot
        image={minimotorsChoices}
        title="mini motors end of week choices"
        caption="At the end of each 'week' you get to choose a reward"
      />
      <Screenshot
        image={psychonautsInGame}
        title="psychonauts megaopolis screenshot"
        caption="Pyschonauts still looks great"
      />
      <Screenshot
        image={psychonautsCG}
        title="psychonauts CG screenshot"
        caption="well... not so much for the pre-rendered cutscenes"
      />
    </Post>
  );
}
